import './App.css';
import logo from './logo.jpg';

import Email from './components/Email.js'
import PlaceIcon from '@material-ui/icons/Place'

import appIconPrescriptionMaker from './images/app-icon-prescription-maker.png';
import appIconBmiCalculator from './images/app-icon-bmi-calculator.png';
import appIconSendex from './images/app-icon-sendex.png';
import appIconBollywoodGame from './images/app-icon-bollywood-game.png';
import appIconHollywoodGame from './images/app-icon-hollywood-game.png';
import appIconColorShooter from './images/app-icon-color-shooter.svg';
import appIconPrastav from './images/app-icon-prastav.png';
import iconWebsite from './images/icon-website.svg';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fab, faEnvelope)

const projects = [
  {
    icon: appIconPrescriptionMaker,
    name: 'Prescription Maker (Android App)',
    description: 'An app that allows doctors to create and send digital prescriptions to their patients. Built with Java and Kotlin in Android Studio, it has garnered over 100,000 installations on the Google Play Store. The app received positive feedback from users, highlighting its user-friendly interface and efficient prescription management.',
    link: 'https://play.google.com/store/apps/details?id=com.mohitatray.prescriptionmaker'
  },
  {
    icon: appIconPrescriptionMaker,
    name: 'Prescription Maker (Web App)',
    description: 'A companion web app for Prescription Maker, developed with Angular and Node.js. Hosted on Ubuntu Server with Postgre SQL. The web app has achieved significant traction with 10,000 Google Search clicks in 28 days.',
    link: 'https://www.digitalprescriptionmaker.com'
  },
  {
    icon: appIconBmiCalculator,
    name: 'BMI Calculator (Android App)',
    description: 'A simple and intuitive app that calculates the Body Mass Index (BMI) for users. Built with Java in Android Studio, it has gained over 1,000 installations on the Google Play Store. Users have praised its user-friendly interface.',
    link: 'https://play.google.com/store/apps/details?id=com.mohitatray.bmicalculator'
  },
  {
    icon: appIconColorShooter,
    name: 'Color Shooter (Game)',
    description: 'A 3D shooting game built using Unity and C#. This game showcases my proficiency in game development and demonstrates my ability to create immersive gameplay experiences. It features stunning visuals, engaging gameplay mechanics, and challenging levels.',
    link: 'https://play.google.com/store/apps/details?id=com.mohitatray.colorshooter'
  },
  {
    icon: appIconBollywoodGame,
    name: 'Bollywood Game (Game)',
    description: 'This nostalgic 2D game, developed with Java and Kotlin in Android Studio, take players on a trip down memory lane. With its simple yet addictive gameplay mechanics, this game has amassed over 500 installations on the Google Play Store, allowing users to relive their childhood memories.',
    link: 'https://play.google.com/store/apps/details?id=com.mohitatray.bollywoodgame'
  },
  {
    icon: appIconHollywoodGame,
    name: 'Hollywood Game',
    description: 'A game similar to Bollywood Game but shows Hollywood movies instead. This game has amassed over 1,000 installations on the Google Play Store.',
    link: 'https://play.google.com/store/apps/details?id=com.mohitatray.hollywoodgame'
  }
]

function App() {
  return (
    <div className='container'>
      <div className='intro-container'>
        <img src={logo} alt="Logo" className='intro-logo' />
        <div className='intro-body'>
          <h1>Mohit Atray</h1>
          <span className='text-with-icon'><PlaceIcon />Udaipur, Rajasthan, India</span>
          <p>
            Hello, I'm Mohit Atray, a passionate programmer with a strong background in Android app development, web development & game development. With expertise in Java, Kotlin, Angular, React, Unity, and Node.js, I have successfully delivered high-quality solutions to clients and developed popular apps on the Google Play Store with over 100,000 downloads. I am seeking exciting freelancing projects that allow me to apply my skills, collaborate with clients, and continue learning in the ever-evolving field of software engineering.
          </p>
        </div>
      </div>

      <div>
        <h3>Services Offered</h3>
        As a freelance software engineer, I offer a range of services to meet your development needs:
        <ul>
          <li>
            Android App Development: Building custom Android applications tailored to your specific requirements, providing intuitive user experiences and seamless functionality.
          </li>
          <li>
            Web Development: Creating responsive and dynamic websites using cutting-edge technologies such as Angular, React, and Node.js, ensuring optimal performance and user engagement.
          </li>
          <li>
            Game Development: Designing and developing captivating games using Unity and C#, delivering immersive experiences across various platforms.
          </li>
        </ul>
      </div>

      <div>
        <h3>Projects</h3>
        <div className='projects-container'>
          {projects.map(project => (
            <div className='project' key={project.link}>
              <img src={project.icon} alt={project.name} />
              <div>
                <h5>{project.name}</h5>
                <p>{project.description}</p>
              </div>
              <a href={project.link} target="_blank" rel="noopener noreferrer"></a>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h3>Contact</h3>
        <p>
          I am open to freelance opportunities and collaborations. Feel free to reach out to me via email at <Email email="hi@mohitatray.xyz"/>. I'm excited to discuss your project requirements and how I can contribute to your success.
        </p>
        <p>
          You can also follow me
          on <a href="https://www.linkedin.com/in/mohitatray"
            target="_blank" rel="noopener noreferrer">LinkedIn</a> to
          learn more about my professional background and see additional projects I have worked on.
        </p>
        <p>
          Thank you for considering my services. I look forward to hearing from you and discussing how we can work together to bring your ideas to life.
        </p>
        <span className='text-with-icon margin-top'>
          <FontAwesomeIcon icon="envelope" size='lg' />Email:&nbsp;
          <Email email="hi@mohitatray.xyz" />
        </span>
        <span className='social-icons'>
          <a href="https://www.linkedin.com/in/mohitatray"
            target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size='lg' />
          </a>
          <a href="https://www.instagram.com/mohitatray"
            target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={["fab", "instagram"]} size='lg' />
          </a>
          <a href="https://github.com/mohitatray"
            target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={["fab", "github"]} size='lg' />
          </a>
          <a href="https://stackoverflow.com/users/7649590/mohit-atray"
            target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={["fab", "stack-overflow"]} size='lg' />
          </a>
        </span>
      </div>

    </div>
  );
}

export default App;
